body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100vw;
  height: 100vh;
  animation: backgroundChange 10s infinite alternate;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@keyframes backgroundChange {
  0% {
      background-color: #ffffff; /* 浅白色 */
  }
  25% {
      background-color: #f0f8ff; /* 浅蓝色 */
  }
  50% {
      background-color: #f5fffa; /* 浅绿色 */
  }
  75% {
      background-color: #ffe4e1; /* 浅粉色 */
  }
  100% {
      background-color: #fafad2; /* 浅黄色 */
  }
}
